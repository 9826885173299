export default class Popup {

    constructor(container){

        if( typeof container === 'string' ) {
            container = document.querySelector(container);
        }

        this.container = container;
        this.inner = container.querySelector('.inner');

        // close button
        container.querySelector('.close').addEventListener('click',()=>{
            this.close();
        })
    }

    open(item_data){
        
        if( typeof this.render === 'function' ) {
            this.render(item_data);
        } 

        this.container.style.display = '';
        
        document.body.popup_content = this;
        setTimeout(()=>{
            document.body.addEventListener('click', this.outside_click_close);
        }, 200);
    }
    
    close(){
        this.container.style.display = 'none';
        document.body.removeEventListener('click', this.outside_click_close);
    }
    
    outside_click_close(e){
        if( e.target == document.body.popup_content.inner ) return;
        if( e.target.closest('.inner') ) return;
        document.body.popup_content.close();
    }

}