import './styles.scss';

import { on_ready } from 'js/utils';
import Popup from './popup';

on_ready(()=>{ 
    init_popup_content();
});

function init_popup_content(){
    const data = popup_content_items;
    const popup = new Popup('.dynamic_popup_content');

    const image = popup.container.querySelector('.image img');
    const name = popup.container.querySelector('.name');
    const position = popup.container.querySelector('.position');
    const about = popup.container.querySelector('.about .description');
    const phone = popup.container.querySelector('.phone .v');
    const social = popup.container.querySelector('.social .btn');
    const email = popup.container.querySelector('.email .v');
    const qualifications = popup.container.querySelector('.qualifications .lists');

    popup.render = function(item_data){

        image.src = item_data.image_url;
        image.style.display = !item_data.image_url ? 'none' : '';

        name.textContent = item_data.name;
        position.textContent = item_data.position;

        about.textContent = item_data.about;
        about.parentElement.style.display = !item_data.about ? 'none' : '';

        phone.innerHTML = `<a href="tel:${item_data.phone}">${item_data.phone}</a>`;
        phone.parentElement.style.display = !item_data.phone ? 'none' : '';
        
        social.innerHTML = `<a href="${item_data.social}">${item_data.social_text}</a>`;
        social.parentElement.style.display = !item_data.social ? 'none' : '';

        email.innerHTML = `<a href="mailto:${item_data.email}">${item_data.email}</a>`;
        email.parentElement.style.display = !item_data.email ? 'none' : '';

        qualifications.innerHTML = '';
        if( item_data.qualifications ) {
            item_data.qualifications.forEach(list=>{
                qualifications.innerHTML += `<li class="list">${list}</li>`;
            })
        }
        qualifications.parentElement.style.display = (!item_data.qualifications.length > 0 ) ? 'none' : '';
    }

    // items
    document.querySelectorAll('.item').forEach(item=>{
        const item_data = data.find(element=>element.id == item.dataset.id);
        item.addEventListener('click',()=>popup.open(item_data)); 
    })
    
}